import { useState, useContext, useCallback, useEffect } from "react";
import { DAppContext } from "./context";
import { Toaster } from "react-hot-toast";
import { ReactComponent as Opensea } from "./opensea.svg";
import { ReactComponent as Twitter } from "./twitter.svg";
import { ReactComponent as Etherscan } from "./etherscan.svg";
import Blood from "./blood.png";
import Vamp from "./vamp.png";
import Serum from "./serums.png";
import Martin from "./martin.png";
import "bootstrap/dist/css/bootstrap.css";

function App() {
  const [count, setCount] = useState(2);
  const [mintedCount, setMintedCount] = useState(0);
  const {
    connectBrowserWallet,
    mint,
    userData,
    transactionHash,
    contractDetails,
  } = useContext(DAppContext);

  const handleCounter = (e) => {
    const { dataset } = e.currentTarget;
    const { pos } = dataset;

    if (pos === "plus" && count < 10) {
      setCount(() => count + 1);
      return;
    }

    if (count - 1 !== 0) {
      setCount(() => count - 1);
    }
  };

  const handleCustomMint = useCallback(() => {
    mint(count);
  }, [count, userData]);

  useEffect(() => {
    if (!!contractDetails) {
      setMintedCount(parseInt(Number(contractDetails.alreadyMinted)));
    }
  }, [contractDetails]);

  return (
    <>
      <header className="banner-container">
        <h1>Sin City Elite</h1>
        <div>
          <div className="socials d-flex align-items-center gap-2 mb-3">
            <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/sin-city-elite" className="social-btn">
              <Opensea />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/sincityelite_"
              className="social-btn"
            >
              <Twitter />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://etherscan.io/address/0x7c41e89e299b1af7c549d42e1483b25bf84b1a11"
              className="social-btn"
            >
              <Etherscan />
            </a>
          </div>

          <div className="mint-container">
            {!!userData ? (
              <>
                {mintedCount > 2500 ? (
                  <>
                    <div className="vampire-form-counter">
                      <button
                        onClick={handleCounter}
                        type="button"
                        className="vampire-btn"
                        data-pos="minus"
                      >
                        -
                      </button>
                      <span> {count} </span>
                      <button
                        onClick={handleCounter}
                        type="button"
                        className="vampire-btn"
                        data-pos="plus"
                      >
                        +
                      </button>
                      <button
                        onClick={handleCustomMint}
                        type="button"
                        className="claim-btn"
                      >
                        Mint
                      </button>
                    </div>
                    <div className="vampire-claimers">
                      <button
                        onClick={() => mint(3)}
                        type="button"
                        className="claim-btn"
                      >
                        Mint 3
                      </button>
                      <button
                        onClick={() => mint(5)}
                        type="button"
                        className="claim-btn"
                      >
                        Mint 5
                      </button>
                      <button
                        onClick={() => mint(10)}
                        type="button"
                        className="claim-btn"
                      >
                        Mint 10
                      </button>
                    </div>
                    {transactionHash && (
                      <a
                        href={`https://etherscan.io/tx/${transactionHash}`}
                        target="_blank"
                        rel="noreferrer"
                        className="claim-btn d-flex align-items-center"
                      >
                        See transaction
                      </a>
                    )}
                  </>
                ) : (
                  <button
                    onClick={() => mint(3)}
                    type="button"
                    className="btn btn-primary"
                  >
                    Free Mint
                  </button>
                )}
              </>
            ) : (
              <button
                onClick={connectBrowserWallet}
                type="button"
                className="btn btn-primary"
              >
                Connect Wallet
              </button>
            )}
          </div>
        </div>
        <p className="banner-desc">
          First bunch of Vamps FREE
          <br />
          <br />
          Then almost free - 0,004
        </p>
        <h4 className="banner-content">
          7777 Elite <br /> Suckers
        </h4>
      </header>
      <div className="container">
        <div className="row about-rows">
          <div className="d-flex align-items-center mb-5">
            <p className="col-12 col-lg-5">
              Long and gory nights in SIn City are just yet to come… Beware of
              Elite Blood Suckers - the most dangerous organization of vampires
              who completely lost their minds during the sunset of the Bull
              Market ERA. Their greed and thirst for blood know no bounds! These
              suckers have already infected and turned half of the Sin City
              people into stupid ghouls who can do nothing but seek fresh
              tokens. Two months ago a group of brave people showed the first
              signs of resistance and started their struggle with the Elite
              Suckers. They named themselves the Guardians of Sin City and
              managed to get the Suckers in trouble in several bloody fights.
              For a moment, the broken citizens of Sin City finally felt a
              glimmer of hope! But Elite Suckers were not that easy to defeat!
              They gathered a dangerous army of 7777 Elite Sucker warriors and
              sieged the last fortress of the Guardians and civilians. Looks
              like the Suckers are not the ones who are about to suck in this
              war…
            </p>
            <div className="col-3 blood-stream">
              <img src={Blood} alt="" />
              <h2>1</h2>
            </div>
            <div className="content-banner col-12 col-lg-4">
              <img src={Vamp} alt="" />
            </div>
          </div>
          <div className="d-flex align-items-center pt-5 mb-5">
            <div className="content-banner content-banner_serum col-12 col-lg-5 ">
              <p>SERUM coming soon</p>
              <img src={Serum} alt="" />
            </div>
            <div className="col-3 blood-stream">
              <img src={Blood} alt="" />
              <h2>2</h2>
            </div>
            <p className="col-12 col-lg-4">
              After the first battle with the big loss of Guardians and
              citizens. So the human scientists had no choice but to make a new
              SERUM that turns the Elite Suckers into Blood Sucker killer
              mutants. Unfortunately, they managed to make only 4444 of them
              which is not enough against the deadly Elite army. Now it’s your
              time to help them out and turn the Suckers into Mutants. Hurry up
              to get the Mutation SERUM and strengthen the army of Guardians.
              But be careful, some of the Mutant Suckers become completely
              uncontrolable and keep on dooming everything on their way,
              including their own creators.
            </p>
          </div>
          <div className="d-flex align-items-center pt-5 mb-5">
            <p className="col-12 col-lg-5">
              So now we have 3 different sides on the same battleground and each
              is ready to put all the effort to bring the rivals to their
              extinction. 10000 crazy Guardians are on the way.
              <br />
              <br />
              THIS WILL BE LEGENDARY!
            </p>
            <div className="col-3 blood-stream">
              <img src={Blood} alt="" />
              <h2>3</h2>
            </div>
            <div className="content-banner col-12 col-lg-4">
              <p>Guardians coming soon</p>
            </div>
          </div>
          <div className="pt-5 mb-5 martin-container">
            <p>Hey J.J. Martin, couldn’t have done this better!</p>
            <img src={Martin} alt="" />
          </div>
          <div className="pt-5 mb-5 d-flex align-items-center justify-content-center">
            <p className="col-md-4 col-12 mx-auto text-center">
              The WAR is on and the GAME is almost ready <br />
              We got <br />
              Step 1 Elite Suckers 7777 <br />
              Step 2 Mutant SERUM 4444
              <br />
              Step 3 Guardians of Sin City 10000 <br />
              Step 4 THE GAME <br />
              <br />
              <br />
              <br />
              Time to Kill and Earn
            </p>
          </div>
        </div>
      </div>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
}

export default App;
